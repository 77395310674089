import axios from "axios";

import { createMsalInstance } from "./msal";
import config from "./config";
import { getBrowserSessionId } from "./getBrowserSessionId";

const browserSessionIdHeader = 'X-NT-Browser-Session-Id';
const browserOriginHeader = 'X-NT-Browser-Request-Origin';
const browserOriginNameHeader = 'X-NT-Browser-Request-Origin-Name';


const createHttpInstance = (token?: string) => {
  const browserSessionId = getBrowserSessionId(browserSessionIdHeader);
  const browserRequestOrigin =
    window.location.origin + window.location.pathname;

  const http = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      [browserSessionIdHeader]: browserSessionId,
      [browserOriginHeader]: browserRequestOrigin,
      [browserOriginNameHeader]: 'eFirds b2c admin panel'
    },
  });

  http.interceptors.response.use(undefined, (err) => {
    console.log(err);
    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      const msalInstance = createMsalInstance();
      return msalInstance
        .acquireTokenSilent({ scopes: config.scopes })
        .then(function (accessTokenResponse) {
          // Acquire token silent success
          // Call API with token
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
          return axios(err.config);
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          if (error.errorMessage.indexOf("interaction_required") !== -1) {
            msalInstance
              .acquireTokenPopup({ scopes: config.scopes })
              .then(function (accessTokenResponse) {
                err.config.__isRetryRequest = true;
                err.config.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
                return axios(err.config);
                // Acquire token interactive success
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
    return Promise.reject(err);
  });

  return http;
};

export default createHttpInstance;

// http.interceptors.response.use(undefined, (err) => {
//   if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
//     return refreshToken()
//         .then((response) => {
//           localStorage.setItem('access_token', response.data.access_token);
//           localStorage.setItem('refresh_token', response.data.refresh_token);

//           err.config.__isRetryRequest = true;
//           err.config.headers.Authorization = `Bearer ${response.data.access_token}`;
//           return axios(err.config);
//         })
//         .catch(() => {
//           localStorage.removeItem('access_token');
//           localStorage.removeItem('refresh_token');
//           browserHistory.push('/signin');
//         });
//   }
//   return Promise.reject(err);
// });
