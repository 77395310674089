import React, { useEffect, useState } from "react";
import { useLazyGetProcessingDates } from "../../graphql/GetBatchStateConversionStatusProcessingDates";
import { useLazyGetBatchStates } from "../../graphql/GetBatchStates";
import { useLazyGetConversionStatuses } from "../../graphql/GetConversionStatuses";
import BatchStatesTable from "./BatchStatesTable";
import ProcessingDateSelect from "./ProcessingDateSelect";
import { ProcessingDataContainer } from "./ProcessingStatusData.css";
import ConversionStatusTable from "./ConversionStatusTable";

const ProcessingStatusData: React.FC = () => {
  const [processingDates, setProcessingDates] = useState<Array<any>>([]);
  const [batchStates, setBatchStates] = useState<Array<any>>([]);
  const [conversionStatuses, setConversionStatuses] = useState<Array<any>>([]);
  const [date, setDate] = useState<string>("");
  const [batchStatus, setBatchStatus] = useState<undefined | string>(undefined);
  const [batchFileType, setBatchFileType] = useState<undefined | string>(
    undefined
  );
  const [conversionStatus, setConversionStatus] = useState<undefined | string>(
    undefined
  );
  const [batchRows, setBatchRows] = useState<number>(10);
  const [conversionRows, setConversionRows] = useState<number>(10);
  const [batchNumOfRec, setBatchNumOfRec] = useState<number>(0);
  const [conversionNumOfRec, setConversionNumOfRec] = useState<number>(0);
  const [batchPage, setBatchPage] = useState<number>(0);
  const [conversionPage, setConversionPage] = useState<number>(0);

  const [
    getBatchStateConversionStatusProcessingDates,
    { data: processingDatesData, loading: loadingProcessingDates },
  ] = useLazyGetProcessingDates();

  const [
    getBatchStates,
    { data: batchStatesData, loading: loadingBatchStates },
  ] = useLazyGetBatchStates();

  const [
    getConversionStatuses,
    { data: conversionStatusesData, loading: loadingConversionStatuses },
  ] = useLazyGetConversionStatuses();

  useEffect(() => {
    getBatchStateConversionStatusProcessingDates();
  }, []);

  useEffect(() => {
    if (date) {
      getBatchStates({
        variables: {
          jobID: date,
          numberOfRecords: batchRows,
          resourceParameters: { status: batchStatus, fileType: batchFileType },
          startingRecord: batchPage === 0 ? 1 : batchPage * batchRows + 1,
        },
      });
    }
  }, [date, batchRows, batchStatus, batchFileType, batchPage]);

  useEffect(() => {
    getConversionStatuses({
      variables: {
        jobID: date,
        numberOfRecords: conversionRows,
        resourceParameters: { status: conversionStatus },
        startingRecord:
          conversionPage === 0 ? 1 : conversionPage * conversionRows + 1,
      },
    });
  }, [date, conversionRows, conversionStatus, conversionPage]);

  useEffect(() => {
    if (processingDatesData) {
      setProcessingDates(
        processingDatesData.getBatchStateConversionStatusProcessingDates
          .processingDateList
      );
      setDate(
        processingDatesData.getBatchStateConversionStatusProcessingDates
          .processingDateList[0]
      );
    }
  }, [processingDatesData]);

  useEffect(() => {
    if (batchStatesData) {
      setBatchStates(batchStatesData.getBatchStates.batchStates);
      setBatchNumOfRec(batchStatesData.getBatchStates.metadata.numberOfRecords);
    }
  }, [batchStatesData]);

  useEffect(() => {
    if (conversionStatusesData) {
      setConversionStatuses(
        conversionStatusesData.getConversionStatuses.conversionStatuses
      );
      setConversionNumOfRec(
        conversionStatusesData.getConversionStatuses.metadata.numberOfRecords
      );
    }
  }, [conversionStatusesData]);

  const resetParams = () => {
    setBatchPage(0);
    setConversionPage(0);
    setBatchStatus(undefined);
    setConversionStatus(undefined);
    setBatchFileType(undefined);
  };

  return (
    <ProcessingDataContainer>
      <ProcessingDateSelect
        processingDates={processingDates}
        date={date}
        setDate={setDate}
        loadingProcessingDates={loadingProcessingDates}
        resetParams={resetParams}
      />
      <BatchStatesTable
        batchStates={batchStates}
        loadingBatchStates={loadingBatchStates}
        setBatchStatus={setBatchStatus}
        batchStatus={batchStatus}
        setBatchFileType={setBatchFileType}
        batchFileType={batchFileType}
        setRowsPerPage={setBatchRows}
        rowsPerPage={batchRows}
        setPage={setBatchPage}
        page={batchPage}
        numberOfRecords={batchNumOfRec}
      />
      <ConversionStatusTable
        conversionStatuses={conversionStatuses}
        loadingConversionStatuses={loadingConversionStatuses}
        setConversionStatus={setConversionStatus}
        conversionStatus={conversionStatus}
        setRowsPerPage={setConversionRows}
        rowsPerPage={conversionRows}
        setPage={setConversionPage}
        page={conversionPage}
        numberOfRecords={conversionNumOfRec}
      />
    </ProcessingDataContainer>
  );
};

export default ProcessingStatusData;
