import { applyMiddleware, createStore } from "redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import createRootReducer from "./combineReducers";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export default function configureStore(preloadedState?: any) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
  );
  return store;
}
