import React, { useState } from "react";
import {
  Stack,
  ActionButton,
  Dialog,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
} from "@fluentui/react";
import { Member } from "../state/singleGroup/types";

interface MemberListItem {
  member: Member;
  handleRemove: (member: Member) => void;
}

export const MemberListItem: React.SFC<MemberListItem> = ({
  member,
  handleRemove,
}) => {
  const [warningModal, toggleWarningModal] = useState<boolean>(false);
  return (
    <Stack horizontal disableShrink style={{ margin: "2px 0" }} key={member.id}>
      <Stack.Item grow={4}>{member.displayName}</Stack.Item>
      <Stack.Item grow={1} align="end">
        <ActionButton
          style={{ float: "right" }}
          iconProps={{ iconName: "Delete" }}
          allowDisabledFocus
          onClick={() => {
            toggleWarningModal(true);
          }}
        >
          Remove
        </ActionButton>
      </Stack.Item>
      <Dialog
        hidden={!warningModal}
        onDismiss={() => toggleWarningModal(false)}
        dialogContentProps={{
          title: "Remove member?",
          subText: `Are you sure you want to remove ${member.displayName} from group?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <DefaultButton
            onClick={() => toggleWarningModal(false)}
            text="Cancel"
          />
          <PrimaryButton onClick={() => handleRemove(member)} text="Remove" />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};
