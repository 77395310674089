import React from "react";
import { useSelector } from "react-redux";
import { State } from "./state/combineReducers";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

import Header from "./components/Header/Header";
import Home from "./containers/Home/Home";
import Groups from "./containers/Groups/Groups";
import Group from "./containers/Group/Group";
import withAuth from "./components/PrivateRoute";
import ProcessingStatusData from "./components/ProcessingStatusData/ProcessingStatusData";

declare const window: any;

function App() {
  const token = useSelector((state: State) => state.auth.token);
  const user = useSelector((state: State) => state.auth.account?.name);
    
  const client = new ApolloClient({
    uri: window._env_.REACT_APP_API_URL,
    request: (operation) => {
      operation.setContext({
        headers: {
          authorization: "Bearer " + token,
        },
      });
    },
  });

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Header user={user} />
          <Router>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/groups" exact component={withAuth(Groups, token)} />
              <Route path="/groups/:id" exact component={withAuth(Group, token)} />
              <Route
                path="/data"
                exact
                component={withAuth(ProcessingStatusData, token)}
              />
            </Switch>
          </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;