import React from "react";
import { Stack, ActionButton, Spinner, SpinnerSize } from "@fluentui/react";
import { signIn } from "../../state/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state/combineReducers";
import { Redirect } from "react-router-dom";

interface HomeProps {
  token: string;
}

const Home: React.SFC<HomeProps> = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.auth.token);
  const loading = useSelector((state: State) => state.auth.loading);
  return (
    <Stack horizontalAlign="center">
      {loading ? (
        <Spinner style={{ minHeight: "10vh" }} size={SpinnerSize.large} />
      ) : !token ? (
        <Stack
          style={{
            marginTop: "20%",
          }}
        >
          <ActionButton
            iconProps={{ iconName: "Signin" }}
            allowDisabledFocus
            onClick={() => {
              dispatch(signIn());
            }}
          >
            Sign in
          </ActionButton>
        </Stack>
      ) : (
        <Redirect to="/groups" />
      )}
    </Stack>
  );
};

export default Home;
