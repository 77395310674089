import React from "react";
import { Stack, ActionButton } from "@fluentui/react";
import { useDispatch } from "react-redux";

import "./Header.css";
import { signOut } from "../../state/auth/actions";
import { push } from "connected-react-router";

interface HeaderProps {
  user?: string | undefined;
  // token: string;
}

const Home: React.SFC<HeaderProps> = ({ user }) => {
  const dispatch = useDispatch();
  return (
    <header className="app-header">
      <main className="container">
        <Stack horizontal disableShrink>
          <Stack.Item grow={4}>
            <ActionButton
              iconProps={{ iconName: "DataManagementSettings" }}
              allowDisabledFocus
              onClick={() => {
                dispatch(push("/data"));
              }}
            >
              Processing Data
            </ActionButton>
          </Stack.Item>
          <Stack.Item grow={4}>
            <ActionButton
              iconProps={{ iconName: "Group" }}
              allowDisabledFocus
              onClick={() => {
                dispatch(push("/groups"));
              }}
            >
              Group App
            </ActionButton>
          </Stack.Item>
          <Stack.Item grow align="end">
            {user ? (
              <ActionButton
                style={{ float: "right" }}
                iconProps={{ iconName: "Signout" }}
                allowDisabledFocus
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign out
              </ActionButton>
            ) : null}
          </Stack.Item>
        </Stack>
      </main>
    </header>
  );
};

export default Home;
