import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import {
  ColumnTitle,
  ConversionTableRow,
  TableCellData,
  TableTitle,
  useTableStyles,
  LoaderOverlay,
  TableContainerStyle,
  TableMainCell,
} from "./TableStyle.css";
import ProcessingStatusSelect from "./ProcessingStatusSelect";
import TablePagination from "./TablePagination";

interface IConversionStatusTable {
  conversionStatuses: Array<any>;
  loadingConversionStatuses: boolean;
  setConversionStatus: Function;
  conversionStatus: undefined | string;
  setRowsPerPage: Function;
  rowsPerPage: number;
  setPage: Function;
  page: number;
  numberOfRecords: number;
}

const ConversionStatusTable: React.FC<IConversionStatusTable> = ({
  conversionStatuses,
  loadingConversionStatuses,
  setConversionStatus,
  conversionStatus,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  page,
  numberOfRecords,
}) => {
  const classes = useTableStyles();
  const TABLE_TYPE = "CONVERSION_STATUS";

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  return (
    <>
      <TableTitle>Conversion Status</TableTitle>
      <TableContainerStyle component={Paper}>
        {loadingConversionStatuses && (
          <LoaderOverlay loaderPosition={conversionStatuses.length}>
            <CircularProgress />
          </LoaderOverlay>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <ColumnTitle>Job ID</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>Status</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>File Name</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>
                  <ColumnTitle>Publication Date</ColumnTitle>
                </ColumnTitle>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableMainCell></TableMainCell>
              <TableMainCell>
                <ProcessingStatusSelect
                  type={TABLE_TYPE}
                  setStatus={setConversionStatus}
                  status={conversionStatus}
                  setPage={setPage}
                />
              </TableMainCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            className={
              loadingConversionStatuses ? classes.loading : classes.notLoading
            }
          >
            {conversionStatuses.length > 0 ? (
              conversionStatuses.map((conversion: any, index: number) => {
                const date = conversion.publicationDate.substring(
                  0,
                  conversion.publicationDate.indexOf("T")
                );
                return (
                  <ConversionTableRow
                    key={`${conversion.jobID}_${index}`}
                    error={conversion.status}
                  >
                    <TableCell>
                      <TableCellData>{conversion.jobID || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>
                        {conversion.status || "N/A"}
                      </TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>
                        {conversion.fileName || "N/A"}
                      </TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{date || "N/A"}</TableCellData>
                    </TableCell>
                  </ConversionTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No results</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainerStyle>
      {numberOfRecords > 10 && (
        <TablePagination
          rowsPerPage={rowsPerPage}
          handleRowsChange={handleRowsChange}
          page={page}
          setPage={setPage}
          totalStatusItems={numberOfRecords}
        />
      )}
    </>
  );
};

export default ConversionStatusTable;
