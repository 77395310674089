import createHttpInstance from "../../http";
import config from "../../config";

export const GET_SINGLE_GROUP_START = "GET_SINGLE_GROUP_START";
export const GET_SINGLE_GROUP_SUCCESS = "GET_SINGLE_GROUP_SUCCESS";
export const GET_SINGLE_GROUP_ERROR = "GET_SINGLE_GROUP_ERROR";

export const GET_SINGLE_GROUP_MEMBERS_START = "GET_SINGLE_GROUP_MEMBERS_START";
export const GET_SINGLE_GROUP_MEMBERS_SUCCESS =
  "GET_SINGLE_GROUP_MEMBERS_SUCCESS";
export const GET_SINGLE_GROUP_MEMBERS_ERROR = "GET_SINGLE_GROUP_MEMBERS_ERROR";

export const REMOVE_GROUP_MEMBER_START = "REMOVE_GROUP_MEMBER_START";
export const REMOVE_GROUP_MEMBER_SUCCESS = "REMOVE_GROUP_MEMBER_SUCCESS";
export const REMOVE_GROUP_MEMBER_ERROR = "REMOVE_GROUP_MEMBER_ERROR";

export const CLEAN_GROUP = "CLEAN_GROUP";

export const ADD_GROUP_MEMBERS_START = "ADD_GROUP_MEMBERS_START";
export const ADD_GROUP_MEMBERS_SUCCESS = "ADD_GROUP_MEMBERS_SUCCESS";
export const ADD_GROUP_MEMBERS_ERROR = "ADD_GROUP_MEMBERS_ERROR";

export function getSingleGroup(groupId: string) {
  return (dispatch: Function, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    dispatch({
      type: GET_SINGLE_GROUP_START,
    });
    return http
      .get(`${config.apiLink}/groups/${groupId}`)
      .then(({ data }) => {
        dispatch(getGroupMembers(groupId));
        dispatch({
          type: GET_SINGLE_GROUP_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_GROUP_ERROR,
        });
      });
  };
}

export function clearGroup() {
  return {
    type: CLEAN_GROUP,
  };
}

export function getGroupMembers(groupId: string) {
  return (dispatch: Function, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    dispatch({
      type: GET_SINGLE_GROUP_MEMBERS_START,
    });
    return http
      .get(`${config.apiLink}/groups/${groupId}/members`)
      .then(({ data }) => {
        dispatch({
          type: GET_SINGLE_GROUP_MEMBERS_SUCCESS,
          payload: data.value,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_GROUP_MEMBERS_ERROR,
        });
      });
  };
}

export function removeGroupMemmber(groupId: string, memberId: string) {
  return (dispatch: Function, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    dispatch({
      type: REMOVE_GROUP_MEMBER_START,
    });
    return http
      .delete(`${config.apiLink}/groups/${groupId}/members/${memberId}/$ref`)
      .then(({ data }) => {
        dispatch({
          type: REMOVE_GROUP_MEMBER_SUCCESS,
          payload: memberId,
        });
        dispatch(getGroupMembers(groupId));
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_GROUP_MEMBER_ERROR,
        });
      });
  };
}

export function addMembersToGrup(groupId: string, members: []) {
  return (dispatch: Function, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    const payload = members.map((el: any) => {
      return `${config.apiLink}/directoryObjects/${el.key}`;
    });
    dispatch({
      type: ADD_GROUP_MEMBERS_START,
    });
    return http
      .patch(`${config.apiLink}/groups/${groupId}`, {
        "members@odata.bind": payload,
      })
      .then(({ data }) => {
        dispatch({
          type: ADD_GROUP_MEMBERS_SUCCESS,
        });
        dispatch(getGroupMembers(groupId));
      })
      .catch((error) => {
        dispatch({
          type: ADD_GROUP_MEMBERS_ERROR,
        });
      });
  };
}
