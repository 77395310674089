import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  MuiThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import {
  useStyles,
  formControlTheme,
  SelectLabel,
  CircularProgressContainer,
} from "./ProcessingSelect.css";

interface IProcessingDateSelect {
  processingDates: Array<string>;
  date: string;
  setDate: Function;
  loadingProcessingDates: boolean;
  resetParams: Function;
}

const ProcessingDateSelect: React.FC<IProcessingDateSelect> = ({
  processingDates,
  date,
  setDate,
  loadingProcessingDates,
  resetParams,
}) => {
  const classes = useStyles();

  const handleChangeDate = (event: any) => {
    setDate(event.target.value);
    resetParams();
  };

  return (
    <MuiThemeProvider theme={formControlTheme}>
      <FormControl variant="outlined" className={classes.formControl}>
        <SelectLabel>
          <InputLabel>Date</InputLabel>
        </SelectLabel>
        <Select
          className={classes.select}
          value={date}
          onChange={handleChangeDate}
          label="Date"
        >
          {!loadingProcessingDates ? (
            processingDates &&
            processingDates.map((dateItem: string) => {
              return (
                <MenuItem key={dateItem} value={dateItem}>
                  {dateItem}
                </MenuItem>
              );
            })
          ) : (
            <CircularProgressContainer>
              <CircularProgress size={25} />
            </CircularProgressContainer>
          )}
        </Select>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default ProcessingDateSelect;
