import { SingleGroupReducer } from "./types";
import {
  GET_SINGLE_GROUP_START,
  GET_SINGLE_GROUP_SUCCESS,
  GET_SINGLE_GROUP_ERROR,
  GET_SINGLE_GROUP_MEMBERS_START,
  GET_SINGLE_GROUP_MEMBERS_SUCCESS,
  GET_SINGLE_GROUP_MEMBERS_ERROR,
  REMOVE_GROUP_MEMBER_START,
  REMOVE_GROUP_MEMBER_SUCCESS,
  REMOVE_GROUP_MEMBER_ERROR,
  ADD_GROUP_MEMBERS_START,
  ADD_GROUP_MEMBERS_SUCCESS,
  ADD_GROUP_MEMBERS_ERROR,
  CLEAN_GROUP,
} from "./actions";

export const initialState: SingleGroupReducer = {
  loading: false,
  groupData: null,
  groupMembers: [],
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_SINGLE_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupData: action.payload,
      };
    case GET_SINGLE_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        groupData: null,
      };
    case GET_SINGLE_GROUP_MEMBERS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupMembers: action.payload,
      };
    case GET_SINGLE_GROUP_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        groupMembers: [],
      };
    case ADD_GROUP_MEMBERS_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_GROUP_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAN_GROUP:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
