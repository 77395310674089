import { useLazyQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

const GET_BATCH_STATE_CONVERSIONS_STATUS_PROCESSING_DATES = gql`
    query getBatchStateConversionStatusProcessingDates {
        getBatchStateConversionStatusProcessingDates {
            processingDateList
        }
    }
    `

export const useLazyGetProcessingDates = () => useLazyQuery(GET_BATCH_STATE_CONVERSIONS_STATUS_PROCESSING_DATES);