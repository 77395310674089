import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../state/users/actions";
import { getGroups } from "../../state/groups/actions";
import { State } from "../../state/combineReducers";
import { Link } from "react-router-dom";
import { Stack, SearchBox, Spinner, SpinnerSize } from "@fluentui/react";
import { Group } from "../../state/groups/types";

const Groups: React.FC = () => {
  const dispatch = useDispatch();
  const groups = useSelector((state: State) => state.groups.groupsList);
  const loading = useSelector((state: State) => state.groups.loading);
  const [filter, setFilter] = useState<string>("");
  const [groupsList, setGroupsList] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(getUsers());
    if (!groups.length) {
      dispatch(getGroups());
    }
  }, [dispatch, groups]);

  useEffect(() => {
    setGroupsList(groups);
  }, [groups]);

  return (
    <main className="container">
      <h2>Groups</h2>
      <Stack>
        <SearchBox
          value={filter}
          placeholder="Search by name"
          onChange={(
            event: React.ChangeEvent<HTMLInputElement> | undefined
          ) => {
            setFilter(event?.target.value as string);
          }}
        />
        {loading ? (
          <Spinner style={{ minHeight: "10vh" }} size={SpinnerSize.large} />
        ) : (
          groupsList
            .filter((group: Group) =>
              group.displayName.toLowerCase().includes(filter)
            )
            .map((el: Group) => (
              <div style={{ paddingTop: "3px" }} key={el.id}>
                <Link to={`/groups/${el.id}`}>
                  {el.displayName}
                  {el.description ? " - " + el.description : ""}
                </Link>
              </div>
            ))
        )}
      </Stack>
    </main>
  );
};

export default Groups;
