import { Auth } from "./types";
import {
  SIGNIN_USER,
  REQUEST_START,
  REQUEST_END,
  SIGNOUT_USER,
} from "./actions";

export const initialState: Auth = {
  loading: false,
  token: null,
  account: null,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case SIGNIN_USER:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case SIGNOUT_USER:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
