import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import {
  useStyles,
  formControlTheme,
  SelectLabel,
} from "./ProcessingSelect.css";

interface IProcessingStatusSelect {
  type: string;
  setStatus: Function;
  status: undefined | string;
  setPage: Function;
}

const ProcessingStatusSelect: React.FC<IProcessingStatusSelect> = ({
  type,
  setStatus,
  status,
  setPage,
}) => {
  const classes = useStyles();

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
    setPage(0);
  };
  const batchStatuses = [
    { status: "TODO", value: "Todo" },
    { status: "EXTRACTING", value: "Extracting" },
    { status: "EXTRACTED", value: "Extracted" },
    { status: "TRANSFORMING", value: "Transforming" },
    { status: "TRANSFORMED", value: "Transformed" },
    { status: "LOADING", value: "Loading" },
    { status: "COMPLETED", value: "Completed" },
    { status: "COMPLETEDWITHERRORS", value: "Completed with errors" },
    { status: "EXTRACTIONERROR", value: "Extraction error" },
    { status: "TRANSFORMATIONERROR", value: "Transformation error" },
    { status: "LOADERROR", value: "Load error" },
    { status: "NOTAPPLICABLE", value: "Not applicable" },
  ];

  const conversionStatuses = [
    { status: "TODO", value: "Todo" },
    { status: "COMPLETED", value: "Completed" },
    {
      status: "MERGEDINSTRUMENTDELISTED",
      value: "Merged instrument delisted",
    },
    {
      status: "TRUNCATEDRECENTINSTRUMENT",
      value: "Truncated recent instrument",
    },
    {
      status: "INSERTEDRECENTINSTRUMENT",
      value: "Inserted recent instrument",
    },
    {
      status: "TRUNCATEDTOPCOUNTRIES",
      value: "Truncated top countries",
    },
    {
      status: "TRUNCATEDINSTRUMENTHISTORYSTAGING",
      value: "Truncated instrument history staging",
    },
    {
      status: "INSERTEDINSTRUMENTHISTORYSTAGING",
      value: "Inserted instrument history staging",
    },
    {
      status: "INSERTEDINSTRUMENTHISTORY",
      value: "Inserted instrument history",
    },
    { status: "TRUNCATEDISINS", value: "Truncated isins" },
    { status: "INSERTEDISINS", value: "Inserted isins" },
    {
      status: "TRUNCATEDINSTRUMENTSTAGING",
      value: "Truncated instrument staging",
    },
    {
      status: "INSERTEDINSTRUMENTSTAGING",
      value: "Inserted instrument staging",
    },
    { status: "UPDATEDFIRSTTRADEDATE", value: "Updated first trade date" },
    { status: "UPDATEDTRADINGVENUES", value: "Updated trading venues" },
    { status: "UPDATEDTRADINGVENUESALL", value: "Updated trading venues all" },
    { status: "MERGEDINSTRUMENT", value: "Merged instrument" },
    { status: "UPDATEDISACTIVE", value: "Updated is active" },
  ];

  const statuses =
    type === "CONVERSION_STATUS" ? conversionStatuses : batchStatuses;

  return (
    <MuiThemeProvider theme={formControlTheme}>
      <FormControl variant="outlined" className={classes.formControl}>
        <SelectLabel>
          <InputLabel>Status</InputLabel>
        </SelectLabel>
        <Select
          className={classes.select}
          value={status || ""}
          onChange={handleChangeStatus}
          label="Status"
        >
          <MenuItem key="none" value={undefined}>
            <i>none</i>
          </MenuItem>
          {statuses.map((statusItem: any) => (
            <MenuItem key={statusItem.status} value={statusItem.status}>
              {statusItem.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default ProcessingStatusSelect;
