import createHttpInstance from "../../http";
import { Dispatch } from "redux";
import config from "../../config";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export function getUsers() {
  return (dispatch: Dispatch, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    dispatch({
      type: GET_USERS_START,
    });
    return http
      .get(`${config.apiLink}/users`)
      .then(({ data }) => {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: data.value,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USERS_ERROR,
        });
      });
  };
}
