import * as React from "react";
import { Redirect } from "react-router-dom";

function withAuth<P extends object>(
  Component: React.ComponentType<P>,
  token: string | null
) {
  return class extends React.Component<P> {
    public render() {
      const { ...props } = this.props;
      return token ? (
        <Component token={token} {...(props as P)} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
  };
}

export default withAuth;
