import { GroupsReducer } from "./types";
import {
  GET_GROUPS_START,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_ERROR,
} from "./actions";

export const initialState: GroupsReducer = {
  loading: false,
  groupsList: [],
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_GROUPS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsList: action.payload,
      };
    case GET_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        groupsList: [],
      };
    default:
      return state;
  }
}

export default reducer;
