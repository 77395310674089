import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  BatchStatusType,
  FileType,
} from "../components/ProcessingStatusData/types";

export interface ResourceParametersInput {
  fileType: FileType;
  status: BatchStatusType;
}

const GET_BATCH_STATES = gql`
  query getBatchStates(
    $jobID: String!
    $numberOfRecords: Int
    $resourceParameters: ResourceParametersInput
    $startingRecord: Int
  ) {
    getBatchStates(
      jobID: $jobID
      numberOfRecords: $numberOfRecords
      resourceParameters: $resourceParameters
      startingRecord: $startingRecord
    ) {
      batchStates {
        jobID
        status
        uRL
        fileType
        cFI
        publicationDate
        totalRefDataNodesInFile
        numberOfErrors
        errorMessage
      }
      metadata {
        numberOfRecords
      }
    }
  }
`;

export const useLazyGetBatchStates = () => useLazyQuery(GET_BATCH_STATES);
