import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import {
  ColumnTitle,
  BatchTableRow,
  TableCellData,
  TableTitle,
  useTableStyles,
  LoaderOverlay,
  TableContainerStyle,
  TableMainCell,
} from "./TableStyle.css";
import ProcessingStatusSelect from "./ProcessingStatusSelect";
import ProcessingFileTypeSelect from "./ProcessingFileTypeSelect";
import TablePagination from "./TablePagination";

interface IBatchStatesTable {
  batchStates: Array<any>;
  loadingBatchStates: boolean;
  setBatchStatus: Function;
  batchStatus: undefined | string;
  setBatchFileType: Function;
  batchFileType: undefined | string;
  setRowsPerPage: Function;
  rowsPerPage: number;
  setPage: Function;
  page: number;
  numberOfRecords: number;
}

const BatchStatesTable: React.FC<IBatchStatesTable> = ({
  batchStates,
  loadingBatchStates,
  setBatchStatus,
  batchStatus,
  setBatchFileType,
  batchFileType,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  page,
  numberOfRecords,
}) => {
  const classes = useTableStyles();
  const TABLE_TYPE = "BATCH_STATES";

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  return (
    <>
      <TableTitle>Batch Status</TableTitle>
      <TableContainerStyle component={Paper}>
        {loadingBatchStates && (
          <LoaderOverlay loaderPosition={batchStates.length}>
            <CircularProgress />
          </LoaderOverlay>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <ColumnTitle>Job ID</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>Status</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>URL</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>File Type</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>CFI</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>
                  <ColumnTitle>Publication Date</ColumnTitle>
                </ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>Total Ref. Data Nodes In File</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>Number Of Errors</ColumnTitle>
              </TableCell>
              <TableCell>
                <ColumnTitle>Error Message</ColumnTitle>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableMainCell></TableMainCell>
              <TableMainCell>
                <ProcessingStatusSelect
                  type={TABLE_TYPE}
                  setStatus={setBatchStatus}
                  status={batchStatus}
                  setPage={setPage}
                />
              </TableMainCell>
              <TableMainCell></TableMainCell>
              <TableMainCell>
                <ProcessingFileTypeSelect
                  setFileType={setBatchFileType}
                  fileType={batchFileType}
                  setPage={setPage}
                />
              </TableMainCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            className={
              loadingBatchStates ? classes.loading : classes.notLoading
            }
          >
            {batchStates.length > 0 ? (
              batchStates.map((batch: any, index: number) => {
                const date = batch.publicationDate.substring(
                  0,
                  batch.publicationDate.indexOf("T")
                );
                return (
                  <BatchTableRow
                    key={`${batch.jobID}_${index}`}
                    error={batch.status}
                  >
                    <TableCell>
                      <TableCellData>{batch.jobID || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{batch.status || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{batch.uRL || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{batch.fileType || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{batch.cFI || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>{date || "N/A"}</TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>
                        {batch.totalRefDataNodesInFile || "N/A"}
                      </TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>
                        {batch.numberOfErrors || "N/A"}
                      </TableCellData>
                    </TableCell>
                    <TableCell>
                      <TableCellData>
                        {batch.errorMessage || "N/A"}
                      </TableCellData>
                    </TableCell>
                  </BatchTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No results</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainerStyle>
      {numberOfRecords > 10 && (
        <TablePagination
          rowsPerPage={rowsPerPage}
          handleRowsChange={handleRowsChange}
          page={page}
          setPage={setPage}
          totalStatusItems={numberOfRecords}
        />
      )}
    </>
  );
};

export default BatchStatesTable;
