import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ConversionStatusType } from "../components/ProcessingStatusData/types";

export interface ConversionStatusResourceParametersInput {
  status: ConversionStatusType;
}

const GET_CONVERSION_STATUSES = gql`
  query getConversionStatuses(
    $jobID: String!
    $numberOfRecords: Int
    $resourceParameters: ConversionStatusResourceParametersInput
    $startingRecord: Int
  ) {
    getConversionStatuses(
      jobID: $jobID
      numberOfRecords: $numberOfRecords
      resourceParameters: $resourceParameters
      startingRecord: $startingRecord
    ) {
      conversionStatuses {
        jobID
        status
        fileName
        publicationDate
      }
      metadata {
        numberOfRecords
      }
    }
  }
`;

export const useLazyGetConversionStatuses = () =>
  useLazyQuery(GET_CONVERSION_STATUSES);
