import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import styled from "styled-components";

export const SelectLabel = styled.div`
  label {
    top: -12px;
    color: #b7b7b6;
  }
`;

export const CircularProgressContainer = styled.div`
  text-align: center;
  margin-top: 5px;
`;

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 110,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    maxHeight: 30,
  },
}));

export const formControlTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          transform: "translate(15px, 6px) scale(0.75)",
        },
      },
    },
  },
});
