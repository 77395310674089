import React from "react";

import { User } from "../state/users/types";
import { TagPicker, ITag } from "@fluentui/react";

interface MembersPickerProps {
  members: User[];
  setMembersForAdd: (membersForAdd?: any[]) => void;
}

export const MembersPicker: React.SFC<MembersPickerProps> = ({
  members,
  setMembersForAdd,
}) => {
  const _testTags = members.map((item) => ({
    key: item.id,
    name: item.displayName,
  })) as ITag[];

  const getTextFromItem = (item: ITag): string => {
    return item.name;
  };

  const onFilterChanged = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    return filterText
      ? _testTags
          .filter(
            (tag) =>
              tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
          )
          .filter((tag) => !listContainsDocument(tag, tagList))
      : [];
  };

  const listContainsDocument = (tag: ITag, tagList: ITag[] | undefined) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return (
      tagList.filter((compareTag) => compareTag.key === tag.key).length > 0
    );
  };

  const handleChange = (items?: ITag[]) => {
    setMembersForAdd(items);
  };

  return (
    <TagPicker
      removeButtonAriaLabel="Remove"
      onResolveSuggestions={onFilterChanged}
      getTextFromItem={getTextFromItem}
      onChange={handleChange}
      pickerSuggestionsProps={{
        suggestionsHeaderText: "Suggested members",
        noResultsFoundText: "No members found",
      }}
      disabled={false}
    />
  );
};

export default MembersPicker;
