declare const window: any;

export default {
  msalConfig: {
    auth: {
      authority: window._env_.AD_B2C_AUTHORITY_URL,
      clientId: window._env_.AD_B2C_CLIENT_ID,
      redirectUri: window.location.href,
    },
    cache: {
      cacheLocation: "sessionStorage" as "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  scopes: [window._env_.AD_B2C_SCOPE_URL],
  apiLink: window._env_.AD_B2C_API_LINK,
};
