import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import authReducer from "./auth/reducer";
import usersReducer from "./users/reducer";
import groupsReducer from "./groups/reducer";
import singleGroupReducer from "./singleGroup/reducer";

import { Auth } from "./auth/types";
import { UsersReducer } from "./users/types";
import { GroupsReducer } from "./groups/types";
import { SingleGroupReducer } from "./singleGroup/types";

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    users: usersReducer,
    groups: groupsReducer,
    singleGroup: singleGroupReducer,
  });

export interface State {
  router: RouterState;
  auth: Auth;
  users: UsersReducer;
  groups: GroupsReducer;
  singleGroup: SingleGroupReducer;
}
