import { Dispatch } from "redux";

import { createMsalInstance } from "../../msal";
import config from "../../config";
import { push } from "connected-react-router";

const msalInstance = createMsalInstance();

export const SIGNIN_USER = "SIGNIN_USER";
export const REQUEST_START = "REQUEST_START";
export const REQUEST_END = "REQUEST_END";

export const SIGNOUT_USER = "SIGNOUT_USER";

export function signIn() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST_START,
    });
    return msalInstance.loginPopup({ scopes: config.scopes }).then((data) => {
      msalInstance
        .acquireTokenSilent({
          scopes: config.scopes,
        })
        .then(({ accessToken }) => {
          dispatch({
            type: SIGNIN_USER,
            payload: {
              token: accessToken,
              account: data.account,
            },
          });
          dispatch(push("/data"));
        });
      dispatch({
        type: REQUEST_END,
      });
    });
  };
}

export function signOut() {
  return async (dispatch: Dispatch) => {
    msalInstance.logout();
    dispatch({
      type: SIGNOUT_USER,
    });
  };
}
