import styled from "styled-components";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface ArrowInterface {
  disabled: boolean;
}

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.div`
  font-size: 14px;
`;

export const NavigationArrows = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 40px;
  margin-left: 20px;

  svg {
    font-size: 12px;
  }
`;

export const Range = styled.div`
  font-size: 16px;
  margin: 0 15px;
`;

export const ArrowBackIosIconStyle = styled(ArrowBackIosIcon)<ArrowInterface>`
  color: ${(props: any) => (props.disabled ? "" : "#a6a6a6")};
  cursor: ${(props: any) => (props.disabled ? "pointer" : "auto")};
`;

export const ArrowForwardIosIconStyle = styled(ArrowForwardIosIcon)<
  ArrowInterface
>`
  color: ${(props: any) => (props.disabled ? "" : "#a6a6a6")};
  cursor: ${(props: any) => (props.disabled ? "pointer" : "auto")};
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 30,
      maxHeight: 60,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
