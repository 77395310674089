import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";

import MembersPicker from "../../components/MembersPicker";
import { getUsers } from "../../state/users/actions";
import { State } from "../../state/combineReducers";
import { User } from "../../state/users/types";

interface MembersModalProps {
  addMemberModal: boolean;
  toggleAddModal: (addMemberModal: boolean) => void;
  currentMembers: User[];
  addMembersToGrup: (groupId: string, members: []) => void;
  groupId: string;
}

export const MembersModal: React.SFC<MembersModalProps> = ({
  addMemberModal,
  toggleAddModal,
  currentMembers,
  addMembersToGrup,
  groupId,
}) => {
  const dispatch = useDispatch();
  const allAvaiableUsers = useSelector((state: State) =>
    state.users.usersList.filter(
      (ar: User) => !currentMembers.find((rm: User) => rm.id === ar.id)
    )
  );
  const [membersForAdd, setMembersForAdd] = useState<any[] | undefined>([]);
  const loadingUsers = useSelector((state: State) => state.users.loading);
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleAddMembers = () => {
    dispatch(addMembersToGrup(groupId, membersForAdd as []));
    toggleAddModal(false);
    setMembersForAdd([]);
  };

  return (
    <Dialog
      hidden={!addMemberModal}
      onDismiss={() => toggleAddModal(false)}
      dialogContentProps={{
        title: "Add members",
        subText: "Select which members you want to add?",
      }}
      modalProps={{ isBlocking: true }}
    >
      {loadingUsers ? (
        <Spinner style={{ minHeight: "50vh" }} size={SpinnerSize.large} />
      ) : (
        <MembersPicker
          members={allAvaiableUsers}
          setMembersForAdd={setMembersForAdd}
        />
      )}

      <DialogFooter>
        <DefaultButton onClick={() => toggleAddModal(false)} text="Cancel" />
        <PrimaryButton
          onClick={handleAddMembers}
          text="Add members"
          disabled={!allAvaiableUsers.length || !membersForAdd!.length}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default MembersModal;
