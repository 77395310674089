import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  MuiThemeProvider,
} from "@material-ui/core";
import {
  useStyles,
  formControlTheme,
  SelectLabel,
} from "./ProcessingSelect.css";

interface IProcessingFileTypeSelect {
  setFileType: Function;
  fileType: undefined | string;
  setPage: Function;
}

const ProcessingFileTypeSelect: React.FC<IProcessingFileTypeSelect> = ({
  setFileType,
  fileType,
  setPage,
}) => {
  const classes = useStyles();

  const handleChangeFileType = (event: any) => {
    setFileType(event.target.value);
    setPage(0);
  };

  const fileTypes = ["DLTINS", "FULINS"];

  return (
    <MuiThemeProvider theme={formControlTheme}>
      <FormControl variant="outlined" className={classes.formControl}>
        <SelectLabel>
          <InputLabel>File type</InputLabel>
        </SelectLabel>
        <Select
          className={classes.select}
          value={fileType || ""}
          onChange={handleChangeFileType}
          label="File type"
        >
          <MenuItem key="none" value={undefined}>
            <i>none</i>
          </MenuItem>
          {fileTypes.map((fileTypeItem: string) => (
            <MenuItem key={fileTypeItem} value={fileTypeItem}>
              {fileTypeItem}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default ProcessingFileTypeSelect;
