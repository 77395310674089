import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state/combineReducers";
import {
  getSingleGroup,
  clearGroup,
  addMembersToGrup,
  removeGroupMemmber,
} from "../../state/singleGroup/actions";
import { Member } from "../../state/singleGroup/types";
import {
  Spinner,
  SpinnerSize,
  SearchBox,
  Stack,
  ActionButton,
} from "@fluentui/react";
import MembersModal from "./MembersModal";
import { MemberListItem } from "../../components/MemberListItem";

interface GroupsProp {
  token: string;
}

const Group: React.SFC<GroupsProp> = ({ token, ...props }) => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: State) => state.router.location.pathname.split("/")[2]
  );
  const group = useSelector((state: State) => state.singleGroup.groupData);
  const loading = useSelector((state: State) => state.singleGroup.loading);
  const groupMembers = useSelector(
    (state: State) => state.singleGroup.groupMembers
  );
  const [filter, setFilter] = useState<string>("");
  const [addMemberModal, toggleAddModal] = useState<boolean>(false);
  useEffect(() => {
    if (location) {
      dispatch(getSingleGroup(location));
    }
    return () => {
      dispatch(clearGroup());
    };
  }, [dispatch, location]);

  const handleRemove = (member: Member) => {
    dispatch(removeGroupMemmber(location, member.id));
  };

  return (
    <main className="container">
      {loading ? (
        <Spinner style={{ minHeight: "50vh" }} size={SpinnerSize.large} />
      ) : (
        <>
          <Stack horizontal>
            <Stack.Item grow={4}>
              <h1>{group?.displayName}</h1>
            </Stack.Item>
            <Stack.Item grow={1} align="end">
              <ActionButton
                style={{ float: "right" }}
                iconProps={{ iconName: "PeopleAdd" }}
                allowDisabledFocus
                onClick={() => {
                  toggleAddModal(true);
                }}
              >
                Add member(s)
              </ActionButton>
            </Stack.Item>
          </Stack>
          <SearchBox
            value={filter}
            placeholder="Search by name"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement> | undefined
            ) => {
              setFilter(event?.target.value as string);
            }}
          />
          <Stack style={{ marginTop: "5px" }}>
            {groupMembers
              .filter((group: any) =>
                group.displayName.toLowerCase().includes(filter)
              )
              .map((member: Member) => {
                return (
                  <MemberListItem
                    key={member.id}
                    member={member}
                    handleRemove={handleRemove}
                  />
                );
              })}
          </Stack>
        </>
      )}
      <MembersModal
        addMemberModal={addMemberModal}
        toggleAddModal={toggleAddModal}
        currentMembers={groupMembers}
        groupId={location}
        addMembersToGrup={addMembersToGrup}
      />
    </main>
  );
};

export default Group;
