import { UsersReducer } from "./types";
import { GET_USERS_START, GET_USERS_SUCCESS, GET_USERS_ERROR } from "./actions";

export const initialState: UsersReducer = {
  loading: false,
  usersList: [],
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: action.payload,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        usersList: [],
      };
    default:
      return state;
  }
}

export default reducer;
