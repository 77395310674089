import createHttpInstance from "../../http";
import { Dispatch } from "redux";

import config from "../../config";

export const GET_GROUPS_START = "GET_GROUPS_START";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_ERROR = "GET_GROUPS_ERROR";

export function getGroups() {
  return (dispatch: Dispatch, getState: Function) => {
    const http = createHttpInstance(getState().auth.token);
    dispatch({
      type: GET_GROUPS_START,
    });
    return http
      .get(`${config.apiLink}/groups`)
      .then(({ data }) => {
        dispatch({
          type: GET_GROUPS_SUCCESS,
          payload: data.value,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GROUPS_ERROR,
        });
      });
  };
}
