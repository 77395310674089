import styled from "styled-components";
import {
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

interface LoaderOverlayInterface {
  loaderPosition: any;
}

interface BatchTableRowInterface {
  error: string;
}

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 30px 0 15px;
`;

export const ColumnTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

export const TableCellData = styled.div`
  font-size: 12px;
  min-width: 90px;
`;

export const BatchTableRow = styled(TableRow)<BatchTableRowInterface>`
  font-size: 12px;
  min-width: 90px;
  td {
    color: ${(props: any) => (props.error.includes("ERROR") ? "#ff0000" : "")};
  }
`;

export const ConversionTableRow = styled(TableRow)<BatchTableRowInterface>`
  font-size: 12px;
  min-width: 90px;
  td {
    color: ${(props: any) => (props.error !== "COMPLETED" ? "#ff0000" : "")};
  }
`;

export const LoaderOverlay = styled.div<LoaderOverlayInterface>`
  position: absolute;
  top: ${(props: any) => (props.loaderPosition ? "50%" : "76%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const TableContainerStyle = styled(TableContainer)`
  position: relative;
`;

export const TableMainCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 5px 5px 5px 0;
  }
`;

export const useTableStyles = makeStyles({
  loading: {
    filter: "blur(1px)",
  },
  notLoading: {
    filter: "blur(0)",
    transition: "all .6s",
  },
});
