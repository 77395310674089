import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import {
  useStyles,
  PaginationContainer,
  Title,
  NavigationArrows,
  Range,
  ArrowBackIosIconStyle,
  ArrowForwardIosIconStyle,
} from "./TablePagination.css";

interface Props {
  rowsPerPage: number;
  handleRowsChange: any;
  page: number;
  setPage: any;
  totalStatusItems: number;
}

const TablePagination: React.FC<Props> = ({
  rowsPerPage,
  handleRowsChange,
  page,
  setPage,
  totalStatusItems,
}) => {
  const classes = useStyles();
  const limitPage = Number.isInteger(totalStatusItems / rowsPerPage)
    ? totalStatusItems / rowsPerPage - 1
    : Math.floor(totalStatusItems / rowsPerPage);

  const handleNextPage = () => {
    if (page < limitPage) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const fromStatus = page === 0 ? 1 : page * rowsPerPage + 1;
  const toStatus = page === 0 ? rowsPerPage : page * rowsPerPage + rowsPerPage;

  return (
    <PaginationContainer>
      <Title>Rows per page:</Title>
      <FormControl className={classes.formControl}>
        <Select
          id="SelectRowsPerPage"
          value={rowsPerPage}
          onChange={handleRowsChange}
        >
          {[10, 25, 50].map((item: number) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Range>
        {`${fromStatus} - ${
          toStatus < totalStatusItems ? toStatus : totalStatusItems
        } of ${totalStatusItems}`}
      </Range>
      <NavigationArrows>
        <ArrowBackIosIconStyle onClick={handlePrevPage} disabled={page > 0} />
        <ArrowForwardIosIconStyle
          onClick={handleNextPage}
          disabled={page < limitPage}
        />
      </NavigationArrows>
    </PaginationContainer>
  );
};

export default TablePagination;
