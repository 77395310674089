import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { ConnectedRouter, push } from "connected-react-router";
import { Fabric, initializeIcons } from "@fluentui/react";

import configureStore, { history } from "./state/configureStore";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import { SIGNIN_USER, SIGNOUT_USER } from "./state/auth/actions";
import createMsalInstance from "./msal";

import "./index.css";

initializeIcons();
const store = configureStore();
const msalInstance = createMsalInstance();

function renderApp() {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Fabric>
          <App />
        </Fabric>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
}

if (!msalInstance.getAccount()) {
  renderApp();
} else {
  msalInstance
    .acquireTokenSilent({ scopes: config.scopes })
    .then((response) => {
      store.dispatch({
        type: SIGNIN_USER,
        payload: {
          token: response.accessToken,
          account: response.account,
        },
      });
      renderApp();
    })
    .catch((err) => {
      store.dispatch({
        type: SIGNOUT_USER,
      });
      store.dispatch(push("/"));
      console.log(err);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
